import React, { Component } from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { media } from '../themes'

import service from '../helper/services'

import { Title, Main, Content } from '../components/styles/pages'

const FormContent = styled.div`
  width: 100%;
  max-width: 440px;
  margin: 0;
  align-self: center;
  display: flex;
  flex-direction: column;
`

const MainContent = styled(Content)`
  display: flex;
  justify-content: center;
  flex-direction: column;
`
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`

const StyledField = styled.div`
  input,
  textarea {
    width: 100%;
    border: 1px solid #ccc;
    padding: 0.3rem 1.2rem 0.15rem;
  }
`

const StyledButton = styled.button`
  width: 50%;
  max-width: 200px;
  align-self: center;

  padding: 1em 1em 0.7em;
  border: none;

  background: black;
  color: white;

  text-transform: uppercase;
  line-height: 100%;

  cursor: pointer;
`

const StyledButtonLink = styled.button`
  background: transparent;
  border: none;
  outline: none;
  appearance: none;
  color: #000;
  font-weight: bold;
  cursor: pointer;
`

const ErrorMessageContainer = styled.div`
  padding: 0.3rem 1.2rem 0;
  color: #f31;
`

const StyledRow = styled.div`
  margin-bottom: 1.2rem;
`

const FormRow = ({ name, type, component = React.input, placeholder }) => (
  <StyledRow>
    <StyledField>
      <Field
        type={type}
        name={name}
        component={component}
        placeholder={placeholder}
      />
    </StyledField>
    <ErrorMessageContainer>
      <ErrorMessage name={name} />
    </ErrorMessageContainer>
  </StyledRow>
)

const FormSectionTitle = styled.h3`
  text-align: center;
  font-weight: 300;
`

const ThankYouMessage = styled.div`
  text-align: center;
`

const SignupNewsletterSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    // .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
})

const ContactMessageSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    // .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  message: Yup.string()
    .min(2, 'Too Short!')
    // .max(50, 'Too Long!')
    .required('Required'),
})

class ContactsPage extends Component {
  render() {
    const { pageResources } = this.props
    return (
      <>
        <Layout pageResources={pageResources}>
          <Main>
            <Title>Contatti</Title>
            <MainContent>
              <FormContent>
                <FormSectionTitle>Newsletter</FormSectionTitle>
                <Formik
                  initialValues={{ email: '', name: '' }}
                  onSubmit={async (values, { setStatus, setSubmitting }) => {
                    let responseStatus = 200

                    try {
                      await service(process.env.GATSBY_NEWSLETTER_API, values)
                    } catch (e) {
                      console.error(e)
                      responseStatus = 500
                    }

                    setStatus(responseStatus)
                    setSubmitting(false)
                  }}
                  validationSchema={SignupNewsletterSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                    isSubmitting,
                    setStatus,
                    status,
                    /* and other goodies */
                  }) => (
                    <StyledForm>
                      <FormRow
                        name={'name'}
                        type="text"
                        placeholder="Your name"
                      />

                      <FormRow
                        name={'email'}
                        type="email"
                        placeholder="Your email address"
                      />
                      {isSubmitting && (
                        <ThankYouMessage>Invio i dati...</ThankYouMessage>
                      )}
                      {status === 200 && (
                        <ThankYouMessage>
                          Iscrizione avvenuta con successo.
                          <br />
                          <StyledButtonLink type="button" onClick={handleReset}>
                            Iscrivi un altro indirizzo email
                          </StyledButtonLink>
                        </ThankYouMessage>
                      )}
                      {status === 500 && (
                        <ThankYouMessage>
                          Errore durante l'iscrizione.
                          <br />
                          <StyledButtonLink
                            type="button"
                            onClick={() => setStatus(null)}
                          >
                            Riprova
                          </StyledButtonLink>
                        </ThankYouMessage>
                      )}
                      {!status && !isSubmitting && (
                        <StyledButton type="submit" disabled={isSubmitting}>
                          Iscriviti
                        </StyledButton>
                      )}
                    </StyledForm>
                  )}
                </Formik>
              </FormContent>
              <FormContent>
                <FormSectionTitle>Info</FormSectionTitle>
                <Formik
                  initialValues={{ email: '', name: '', message: '' }}
                  validationSchema={ContactMessageSchema}
                  onSubmit={async (values, { setStatus, setSubmitting }) => {
                    let responseStatus = 200

                    try {
                      await service(process.env.GATSBY_CONTACT_API, values)
                    } catch (e) {
                      console.error(e)
                      responseStatus = 500
                    }

                    setStatus(responseStatus)
                    setSubmitting(false)
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleReset,
                    isSubmitting,
                    setStatus,
                    status,
                    /* and other goodies */
                  }) => (
                    <StyledForm>
                      <FormRow
                        name={'name'}
                        type="text"
                        placeholder="Your name"
                      />

                      <FormRow
                        name={'email'}
                        type="email"
                        placeholder="Your email address"
                      />

                      <FormRow
                        name={'message'}
                        component="textarea"
                        placeholder="A message..."
                      />
                      {isSubmitting && (
                        <ThankYouMessage>Invio i dati...</ThankYouMessage>
                      )}
                      {status === 200 && (
                        <ThankYouMessage>
                          Messaggio inviato con successo.
                          <br />
                          <StyledButtonLink type="button" onClick={handleReset}>
                            Invia un altro messaggio
                          </StyledButtonLink>
                        </ThankYouMessage>
                      )}
                      {status === 500 && (
                        <ThankYouMessage>
                          Errore durante l'invio del messaggio.
                          <br />
                          <StyledButtonLink
                            type="button"
                            onClick={() => setStatus(null)}
                          >
                            Riprova
                          </StyledButtonLink>
                        </ThankYouMessage>
                      )}
                      {!status && !isSubmitting && (
                        <StyledButton type="submit" disabled={isSubmitting}>
                          Invia
                        </StyledButton>
                      )}
                    </StyledForm>
                  )}
                </Formik>
              </FormContent>
            </MainContent>
          </Main>
        </Layout>
      </>
    )
  }
}

export default ContactsPage
